exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lite-tsx": () => import("./../../../src/pages/lite.tsx" /* webpackChunkName: "component---src-pages-lite-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-baseball-team-manager-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/baseballTeamManager/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-baseball-team-manager-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-langchain-experiments-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/langchainExperiments/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-langchain-experiments-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-map-app-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/mapApp/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-map-app-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-metronome-app-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/metronomeApp/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-metronome-app-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-mood-on-repeat-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/moodOnRepeat/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-mood-on-repeat-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-personal-website-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/personalWebsite/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-personal-website-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-reasoning-decomp-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/reasoningDecomp/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-reasoning-decomp-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-strava-wrapped-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/projects/stravaWrapped/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-strava-wrapped-index-mdx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

